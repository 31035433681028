<template>
	<div id="PasswordReset" class="input-heading">
		<div class="input-section encore-background" id="length-of-container">
			<h3>
				{{ languageStrings.changePassword }} <span class="phone-number-color">{{ phoneNumberParsed?.number?.national }}</span>
			</h3>
			<div class="new-password-container">
			<!-- <label for="currentPassword">{{ languageStrings.currentPassword }}</label> -->
			<div class="password-container">
				<input
					:type="unhideOld ? 'text' : 'password'"
					id="currentPassword"
					name="currentPassword"
					class="input-text-position input-for-change-password"
					@keydown.enter="changePassword()"
					v-model="currentPassword"
					:placeholder="languageStrings.currentPassword"
					autocomplete="password"
				/>
				<span
					@click="unhideOld = unhideOld ? false : true"
					:class="unhideOld ? 'hidden' : ''"
					:title="unhideOld ? languageStrings.hidePassword : languageStrings.showPassword"
				>
				<svg id="eyeIcon" width="35px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						 <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0002 5C9.89024 5 7.99432 5.92747 6.49128 7.00884C4.98034 8.0959 3.78013 9.40024 3.04026 10.2985C2.21965 11.2948 2.21965 12.7052 3.04026 13.7015C3.78013 14.5998 4.98034 15.9041 6.49128 16.9912C7.99432 18.0725 9.89024 19 12.0002 19C14.1101 19 16.006 18.0725 17.5091 16.9912C19.02 15.9041 20.2202 14.5998 20.9601 13.7015C21.7807 12.7052 21.7807 11.2948 20.9601 10.2985C20.2202 9.40025 19.02 8.0959 17.5091 7.00885C16.006 5.92747 14.1101 5 12.0002 5ZM12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z" fill="#6305C9"></path>
					</g>
				</svg>
				</span>
			</div>

			<!-- <label for="newPassword">{{ languageStrings.newPassword }}</label> -->
			<div class="password-container">
				<input
					:type="unhidePassword ? 'text' : 'password'"
					id="newPassword"
					name="newPassword"
					class="input-text-position input-for-change-password"
					@keydown.enter="changePassword()"
					v-model="newPassword"
					:placeholder="languageStrings.newPassword"
					autocomplete="new-password"
					:minlength="serverRules.passwordRules?.minimumLength"
					:maxlength="serverRules.passwordRules?.maximumLength"
					:pattern="`.{${serverRules.passwordRules?.minimumLength},}`"
				/>
				<span
					@click="unhidePassword = unhidePassword ? false : true"
					:class="unhidePassword ? 'hidden' : ''"
					:title="unhidePassword ? languageStrings.hidePassword : languageStrings.showPassword"
				>
				<svg id="eyeIcon" width="35px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier"> 
						<path fill-rule="evenodd" clip-rule="evenodd" d="M12.0002 5C9.89024 5 7.99432 5.92747 6.49128 7.00884C4.98034 8.0959 3.78013 9.40024 3.04026 10.2985C2.21965 11.2948 2.21965 12.7052 3.04026 13.7015C3.78013 14.5998 4.98034 15.9041 6.49128 16.9912C7.99432 18.0725 9.89024 19 12.0002 19C14.1101 19 16.006 18.0725 17.5091 16.9912C19.02 15.9041 20.2202 14.5998 20.9601 13.7015C21.7807 12.7052 21.7807 11.2948 20.9601 10.2985C20.2202 9.40025 19.02 8.0959 17.5091 7.00885C16.006 5.92747 14.1101 5 12.0002 5ZM12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z" fill="#6305C9"></path>
					</g>
				</svg>
				</span>
			</div>

			<!-- <label for="confirmPassword">{{ languageStrings.confirmPassword }}</label> -->
			<div class="password-container">
				<input
					:type="unhideConfirm ? 'text' : 'password'"
					id="confirmPassword"
					name="confirmPassword"
					class="input-text-position input-for-change-password"
					@keydown.enter="changePassword()"
					v-model="confirmPassword"
					:placeholder="languageStrings.confirmPassword"
					autocomplete="off"
					:minlength="serverRules.passwordRules?.minimumLength"
					:maxlength="serverRules.passwordRules?.maximumLength"
					:pattern="`.{${serverRules.passwordRules?.minimumLength},}`"
				/>
				<span
					@click="unhideConfirm = unhideConfirm ? false : true"
					:class="unhideConfirm ? 'hidden' : ''"
					:title="unhideConfirm ? languageStrings.hidePassword : languageStrings.showPassword"
				>
				<svg id="eyeIcon" width="35px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						 <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0002 5C9.89024 5 7.99432 5.92747 6.49128 7.00884C4.98034 8.0959 3.78013 9.40024 3.04026 10.2985C2.21965 11.2948 2.21965 12.7052 3.04026 13.7015C3.78013 14.5998 4.98034 15.9041 6.49128 16.9912C7.99432 18.0725 9.89024 19 12.0002 19C14.1101 19 16.006 18.0725 17.5091 16.9912C19.02 15.9041 20.2202 14.5998 20.9601 13.7015C21.7807 12.7052 21.7807 11.2948 20.9601 10.2985C20.2202 9.40025 19.02 8.0959 17.5091 7.00885C16.006 5.92747 14.1101 5 12.0002 5ZM12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10ZM8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12Z" fill="#6305C9"></path>
					</g>
				</svg>
				</span>
			</div>
		</div>
			<span v-if="capsLockOn" class="warning-message-text">{{languageStrings.capLockIsOn}}</span>
			<div class="button-container">
				<button class="btn extra-padding new-encore-button" type="button" :title="languageStrings.changePassword" @click="changePassword()">
					{{ languageStrings.changePassword }}
				</button>
				<router-link to="/" :title="languageStrings.back" class="btn back extra-padding new-encore-button">{{ languageStrings.back }}</router-link>
			</div>
			<small class="center-text"
				>{{ languageStrings.passwordRequirements1 }} {{ serverRules?.passwordRules?.minimumLength }}
				{{ languageStrings.passwordRequirements2 }}
				<span v-if="serverRules?.passwordRules?.minimumNumberOfCharacterClasses > 0">
					{{ languageStrings.passwordRequirements3 }} {{ serverRules?.passwordRules?.minimumNumberOfCharacterClasses }}
					{{ languageStrings.passwordRequirements4 }}
				</span>
			</small>
			<div id="pw-strength-container">
				<p>
					{{ languageStrings.passwordStrength }}:
					<span v-if="newPassword?.length > 0 && strengthCheck =='weak'" class="score-words" :class="strengthCheck">{{ languageStrings.weak }}</span>
					<span v-if="newPassword?.length > 0 && strengthCheck =='medium'" class="score-words" :class="strengthCheck">{{ languageStrings.medium }}</span>
					<span v-if="newPassword?.length > 0 && strengthCheck =='strong'" class="score-words" :class="strengthCheck">{{ languageStrings.strong }}</span>
				</p>
				<progress id="pw-strength" class="custom-bar" :class="strengthCheck" :value="pwStrength" max="100">Password strength</progress>
			</div>
		</div>
	</div>
</template>

<script>
import router from "@/router";
import sharedScripts from "@/dependencies/sharedScripts";
import { onBeforeUnmount } from "vue";
import { parsePhoneNumber } from "awesome-phonenumber";

export default {
	name: "ChangePassword",
	props: {
		playerState: Object,
		capsLockOn: Boolean,
		serverRules: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			unhideOld: false,
			unhidePassword: false,
			unhideConfirm: false,
			phoneNumber: "",
			currentPassword: "",
			newPassword: "",
			confirmPassword: "",
			matchedCharClasses: {},
			validNumberCharClasses: false,
			pwStrength: 0,
			strengthCheck: "weak",
			// minimumNumberOfCharacterClasses: this.serverRules.passwordRules?.minimumNumberOfCharacterClasses,
			// minimumLength: this.serverRules.passwordRules?.minimumLength,
			// maximumLength: this.serverRules.passwordRules?.maximumLength,
			// inputPattern: `.{${this.serverRules.passwordRules?.minimumLength},}`,
			phoneNumberParsed: {},
		};
	},
	watch: {
		newPassword() {
			this.passwordStrengthCheck();
		},
		confirmPassword() {
			this.passwordStrengthCheck();
		},
	},
	created() {
		let phone = `+${this.playerState.phoneCountryCode}${this.playerState.phoneNumber}`;
		this.phoneNumberParsed = parsePhoneNumber(phone);
	},
	methods: {
		passwordStrengthCheck() {
			this.pwStrength = this.scorePassword(this.newPassword);

			if (this.pwStrength <= 33) this.strengthCheck = "weak";
			if (this.pwStrength > 33 && this.pwStrength < 66) this.strengthCheck = "medium";
			if (this.pwStrength >= 66) this.strengthCheck = "strong";

			if (this.newPassword === this.confirmPassword && this.newPassword.length >= this.serverRules.passwordRules?.minimumLength) {
				this.matchedCharClasses = this.characterClassCheck(this.newPassword, this.serverRules.passwordRules?.minimumNumberOfCharacterClasses);
				this.validNumberCharClasses = this.matchedCharClasses.characterClassesValid;
			}
		},
		async changePassword() {
			let hasErrors = false;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			if (!this.newPassword || !this.confirmPassword || !this.currentPassword) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.passwordFieldsEmpty;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (this.newPassword !== this.confirmPassword) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.passwordDoNotMatch;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (this.newPassword?.length < this.serverRules.passwordRules?.minimumLength) {
				this.status.ok = false;
				this.status.message = `${this.languageErrorStrings.passwordMustHaveAtLeast} ${this.serverRules.passwordRules?.minimumLength} ${this.languageErrorStrings.characters}`;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (this.newPassword?.length > 0 && !this.validNumberCharClasses) {
				this.status.ok = false;
				this.status.message = `${this.languageErrorStrings.passwordMustHaveAtLeast} ${this.serverRules.passwordRules?.minimumNumberOfCharacterClasses} ${this.languageErrorStrings.characterClassesError}`;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (hasErrors === true) return;

			let body = {
				CurrentPassword: this.currentPassword,
				NewPassword: this.newPassword,
			};

			let requestUrl = new URL("/api/v1/user/password", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (fetchStatus.ok) this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);

				let playerState = {};
				if (this.status.message === "Success") {
					let playerState = {};
					this.status.message = this.languageErrorStrings.passwordChangeSuccessfully;
					// this.eventBus.emit("updateStatus", this.status);
					this.eventBus.emit("updatePlayerState", playerState);
					router.push("/");
				}

				this.eventBus.emit("updateStatus", this.status);
			} catch (e) {
				console.error(e);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
	text-align: center;
}

#PasswordReset {
	position: fixed;
	display: grid;
	align-items: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	background: rgba(0, 0, 0, 0.6);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	#PasswordReset {
		margin-bottom: 100px;
	}
}

#PasswordReset div {
	margin: auto;
	width: 50vw;
}

.input-section {
	display: flex;
	flex-direction: column;
	width: 85%;
	margin: 110px auto;
	background-color: #24272c;
	padding: 15px;
	border-radius: 8px;
	/* box-shadow: 2px 3px 8px rgb(0 0 0 / 75%), inset 4px 3px 10px 4px rgb(42 63 88 / 30%), inset -2px -2px 10px 2px rgb(0 0 0 / 30%); */
}

.button-container {
	display: flex;
	margin-top: 20px;
}

.back {
	/* background: #efefef; */
	/* padding: 5px 10px; */
	margin: auto auto auto 0;
	text-decoration: none;
	/* position: absolute; */
	/* top: 30px; */
}

input {
	/* border-radius: 15px; */
	color: #6305C9;
	/* width: 130%; */
	margin-bottom: 25px;
}

/* .new-password-container {
	display: grid;
	justify-content: center;
} */

.input-text-position {
	padding: 8px 10px;
}

#main-nav-hidden {
	height: 100vh;
}

#length-of-container {
	max-width: 800px;
}

.btn {
	border: transparent;
}

.password-container span {
	color: #6305C9;
	top: 7px;
}

.password-container span::after {
	right: 13px;
	top: -4px;
}

@media (min-width: 768px) {
	.input-section {
		padding: 30px;
		width: 50%;
	}

	.back {
		top: unset;
	}
}
</style>
